import { lazy, Suspense, useEffect } from "react";
import { Provider } from "react-redux";
import {
  BrowserRouter,
  Route,
  Routes
} from "react-router-dom";
import createStore from "../../state/store";
import Amortization from "../know/amortization";
import Footer from "./../../components/Footer";
import Navbar from "./../../components/Navbar";
import NotificationToast from "./../../components/NotificationToast";
import RequireAuth from "./RequireAuth";
import "./styles.scss";

import { ThemeProvider } from "@mui/material";

import { appTheme } from "../../components/appTheme";

const NoPage = lazy(() => import("../home/NoPage"));
const AboutPage = lazy(() => import("../home/about"));
const PoliciesPage = lazy(() => import("../home/policies"));
const Product = lazy(() => import("../home/product"));
const ProductLend = lazy(() => import("../home/ProductLend"));
const ProductBorrow = lazy(() => import("../home/ProductBorrow"));
const ProductGift = lazy(() => import("../home/ProductGift"));
const ProductLoanAgreement = lazy(() => import("../home/ProductLoanAgreement"));
const UseCaseStudentLoans = lazy(() => import("../home/UseCaseStudentLoans"));
const UseCaseRealEstateLoans = lazy(() => import("../home/UseCaseRealEstateLoans"));
const UseCaseCarLoans = lazy(() => import("../home/UseCaseCarLoans"));
const UseCaseMovingLoans = lazy(() => import("../home/UseCaseMovingLoans"));
const UseCaseBusinessLoans = lazy(() => import("../home/UseCaseBusinessLoans"));
const ChangepassPage = lazy(() => import("./../changepass"));
const GetStarted = lazy(() => import("../home/GetStarted"));
const GetStartedBorrower = lazy(() => import("../home/GetStartedBorrower"));
const FaqPage = lazy(() => import("./../faq"));
const ForgotpassPage = lazy(() => import("./../forgotpass"));
const HomePage = lazy(() => import("./../home"));
const KnowPage = lazy(() => import("../know"));
const CustomLoan = lazy(() => import("../know/customLoan"));
const KnowWhenNamma = lazy(() => import("../know/whenNamma"));
const BeyondLoanAgreement = lazy(() => import("../know/beyondLoanAgreement"));
const ApproachLender = lazy(() => import("../know/approachLender"));
const WhyBeLender = lazy(() => import("../know/whyBeLender"));
const WhyFFL = lazy(() => import("../know/whyFFL"));
const FamMortLoans = lazy(() => import("../know/famMortLoans"));
const FFLFAQ = lazy(() => import("../know/fflFaq"));
const FFLTaxes = lazy(() => import("../know/fflTaxes"));
const MistakesFLL = lazy(() => import("../know/mistakesFFL"));
const TapNetwork = lazy(() => import("../know/tapNetwork"));
const ConvCosts = lazy(() => import("../know/convCosts"));
const GiftTaxRules = lazy(() => import("../know/giftTaxRules"));
const BuildCredit = lazy(() => import("../know/buildCredit"));
const LendInterest = lazy(() => import("../know/lendInterest"));
const CCLoan = lazy(() => import("../know/ccLoan"));
const StartupBizFF = lazy(() => import("../know/startupBizFF"));
const DigitalContractMaker = lazy(() => import("../know/digitalContractMaker"));
const LoanTerms = lazy(() => import("./../loanflow/LoanTerms"));
const LoanWishDetails = lazy(() => import("./../loanflow/LoanWishDetails"));
const LoanWishPublished = lazy(() => import("./../loanflow/LoanWishPublished"));
const LoanWish = lazy(() => import("../home/LoanWish"));
const LoanInfo = lazy(() => import("./../loanflow/LoanInfo"));
const LoanServices = lazy(() => import("./../loanflow/LoanServices"));
const LoanInitiatorParty = lazy(() => import("../loanflow/LoanInitiatorParty"));
const LoanRecipientParty = lazy(() =>
  import("./../loanflow/LoanRecipientParty"),
);
const LoanRecord = lazy(() => import("../loanRecord/LoanRecord"));
const LoanReview = lazy(() => import("./../loanflow/LoanReview"));
const LoanSignature = lazy(() => import("./../loanflow/LoanSignature"));
const ExecutedLoan = lazy(() => import("./../loanflow/ExecutedLoan"));
const RejectedLoan = lazy(() => import("../loanflow/RejectedLoan"));
const LoanSuccess = lazy(() => import("./../loanflow/LoanSuccess"));
const LoanRejected = lazy(() => import("./../loanflow/LoanRejected"));
const RejectReason = lazy(() => import("./../loanflow/RejectReason"));
const RequestSent = lazy(() => import("../loanflow/RequestSent"));

const GiftTerms = lazy(() => import("./../giftflow/GiftTerms"));
const GiftSource = lazy(() => import("./../giftflow/GiftSource"));
const GiftInfo = lazy(() => import("./../giftflow/GiftInfo"));
const GiftTakerDetails = lazy(() => import("./../giftflow/GiftTakerDetails"));
const GiftDonorDetails = lazy(() => import("../giftflow/GiftDonorDetails"));
const GiftReview = lazy(() => import("./../giftflow/GiftReview"));
const GiftSignature = lazy(() => import("./../giftflow/GiftSignature"));
const ExecutedGift = lazy(() => import("./../giftflow/ExecutedGift"));
const RejectedGift = lazy(() => import("../giftflow/RejectedGift"));
const GiftSuccess = lazy(() => import("./../giftflow/GiftSuccess"));
const GiftRejected = lazy(() => import("./../giftflow/GiftRejected"));
const GiftRejectReason = lazy(() => import("./../giftflow/GiftRejectReason"));
const GiftRequestSent = lazy(() => import("../giftflow/GiftRequestSent"));

const Profile = lazy(() => import("./../profile"));

const SigninPage = lazy(() => import("./../signin"));
const SignupPage = lazy(() => import("./../signup"));
const SignupRecieved = lazy(() => import("./../signup/SignupRecieved"));
const UserLoans = lazy(() => import("./../dashboard/UserLoans"));
const VerificationPage = lazy(() => import("./../verification"));

function App() {
  useEffect(() => { }, []);

  return (
    <ThemeProvider theme={appTheme}>
      <Provider store={createStore()}>
        <Suspense fallback={<>Loading</>} >

          <BrowserRouter>
            <Navbar color="white" spaced={true} logo="/namma-logo-eggplant.svg" />
            <NotificationToast />
            <Routes>
              <Route path="/">
                <Route index element={<HomePage />} />
                <Route path="/product">
                  <Route index element={<Product />} />
                  <Route path="loan-agreement-template-family-friends" element={<ProductLoanAgreement />}></Route>
                </Route>
                <Route path="/know">
                  <Route index element={<KnowPage />} />
                  <Route path="taxes-implications-lending-borrowing-family-friends" element={<FFLTaxes />} />
                  <Route path="charge-interest-family-friend-loan" element={<LendInterest />} />
                  <Route path="avoid-common-lending-pitfalls-relationship-issues-regrets" element={<MistakesFLL />} />
                  <Route path="alternatives-to-conventional-bank-loans-advantages" element={<ConvCosts />} />
                  <Route path="mortgage-gift-tax-rules" element={<GiftTaxRules />} />
                  <Route path="loan-amortization-calculator-interest-principal-schedule" element={<Amortization />} />
                  <Route path="why-family-friends-bank" element={<WhyFFL />} />
                </Route>
                <Route path="/borrow101">
                  <Route index element={<KnowPage />} />
                  <Route path="become-hard-money-lender" element={<BeyondLoanAgreement />} />
                  <Route path="borrowing-money-from-friends-family" element={<TapNetwork />} />
                  <Route path="cc-loan" element={<CCLoan />} />
                  <Route path="negotiate-loan-terms" element={<CustomLoan />} />
                  <Route path="digital-contract-maker-app" element={<DigitalContractMaker />} />
                  <Route path="friendly-loans-faq" element={<FFLFAQ />} />
                  <Route path="house-without-mortgage" element={<FamMortLoans />} />
                  <Route path="use-social-credibility" element={<ApproachLender />} />
                  <Route path="startup-business-loans-friends-and-family" element={<StartupBizFF />} />
                  <Route path="when-namma" element={<KnowWhenNamma />} />
                  <Route path="alternative-asset-class" element={<WhyBeLender />} />
                  <Route path="build-credit" element={<BuildCredit />} />
                </Route>
                <Route path="/about" element={<AboutPage />} />
                <Route path="/changepass" element={<ChangepassPage />} />
                <Route path="/faq" element={<FaqPage />} />
                <Route path="/forgotpass" element={<ForgotpassPage />} />
                <Route path="/get-started" element={<GetStarted />} />
                <Route path="/get-started-borrower" element={<GetStartedBorrower />} />
                <Route path="/join-community" element={<SignupRecieved />} />
                <Route path="/product-gift" element={<ProductGift />} />
                <Route path="/product-lend" element={<ProductLend />} />
                <Route path="/product-borrow" element={<ProductBorrow />} />
                <Route path="/best-student-loans-payoff-alternative" element={<UseCaseStudentLoans />} />
                <Route path="/best-realestate-mortgage-property-loans-alternative" element={<UseCaseRealEstateLoans />} />
                <Route path="/best-moving-relocation-expense-loans-alternative" element={<UseCaseMovingLoans />} />
                <Route path="/best-fast-auto-car-loan-payoff-alternative" element={<UseCaseCarLoans />} />
                <Route path="/best-business-loans-working-capital-alternative" element={<UseCaseBusinessLoans />} />
                <Route path="/policies" element={<PoliciesPage />} />
                <Route path="/signin" element={<SigninPage />} />
                <Route path="/signup" element={<SignupPage />} />
                <Route path="/verification" element={<VerificationPage />} />
              </Route>
              <Route path="/dashboard" element={<RequireAuth><UserLoans /></RequireAuth>} />
              <Route path="/loan-terms" element={<RequireAuth><LoanTerms /></RequireAuth>} />
              <Route path="/:id/loan-terms" element={<RequireAuth><LoanTerms /></RequireAuth>} />
              <Route path="/:id/loan-wish-details" element={<RequireAuth><LoanWishDetails /></RequireAuth>} />
              <Route path="/:id/loan-wish-published" element={<RequireAuth><LoanWishPublished /></RequireAuth>} />
              <Route path="/:id/loan-wish" element={<LoanWish />} />
              <Route path="/:id/loan-info" element={<RequireAuth><LoanInfo /></RequireAuth>} />
              <Route path="/:id/loan-party" element={<RequireAuth><LoanInitiatorParty /></RequireAuth>} />
              <Route path="/:id/loan-recipient-party" element={<RequireAuth><LoanRecipientParty /></RequireAuth>} />
              <Route path="/:id/loan-record" element={<RequireAuth><LoanRecord /></RequireAuth>} />
              <Route path="/:id/loan-review" element={<RequireAuth><LoanReview /></RequireAuth>} />
              <Route path="/:id/loan-success" element={<RequireAuth><LoanSuccess /></RequireAuth>} />
              <Route path="/:id/loan-services" element={<LoanServices />} />
              <Route path="/:id/loan-signature" element={<RequireAuth><LoanSignature /></RequireAuth>} />
              <Route path="/:id/executed" element={<RequireAuth><ExecutedLoan /></RequireAuth>} />
              <Route path="/:id/rejected" element={<RequireAuth><RejectedLoan /></RequireAuth>} />
              <Route path="/:id/reject-loan" element={<RequireAuth><LoanRejected /></RequireAuth>} />
              <Route path="/:id/request-sent" element={<RequireAuth><RequestSent /></RequireAuth>} />
              <Route path="/:id/loan-review-reject" element={<RequireAuth><RejectReason /></RequireAuth>} />
              <Route path="/gift-terms" element={<RequireAuth><GiftTerms /></RequireAuth>} />
              <Route path="/:id/gift-terms" element={<RequireAuth><GiftTerms /></RequireAuth>} />
              <Route path="/:id/gift-info" element={<RequireAuth><GiftInfo /></RequireAuth>} />
              <Route path="/:id/gift-taker" element={<RequireAuth><GiftTakerDetails /></RequireAuth>} />
              <Route path="/:id/gift-donor" element={<RequireAuth><GiftDonorDetails /></RequireAuth>} />
              <Route path="/:id/gift-source" element={<RequireAuth><GiftSource /></RequireAuth>} />
              <Route path="/:id/gift-review" element={<RequireAuth><GiftReview /></RequireAuth>} />
              <Route path="/:id/gift-success" element={<RequireAuth><GiftSuccess /></RequireAuth>} />
              <Route path="/:id/gift-signature" element={<RequireAuth><GiftSignature /></RequireAuth>} />
              <Route path="/:id/gift-executed" element={<RequireAuth><ExecutedGift /></RequireAuth>} />
              <Route path="/:id/gift-rejected" element={<RequireAuth><RejectedGift /></RequireAuth>} />
              <Route path="/:id/gift-review-reject" element={<RequireAuth><GiftRejectReason /></RequireAuth>} />
              <Route path="/:id/reject-gift" element={<RequireAuth><GiftRejected /></RequireAuth>} />
              <Route path="/:id/gift-request-sent" element={<RequireAuth><GiftRequestSent /></RequireAuth>} />
              <Route path="/profile/:id" element={<RequireAuth><Profile /></RequireAuth>} />
              <Route path="*" element={<NoPage />} />
            </Routes>
            <Footer

              size="medium"
              logo="/namma-logo-white-footer.svg"
              description="Namma's vision is to make lending and borrowing between family and friends easy and less awkward. By providing with the tools to formalize, legalize and track these monetary exchanges, borrowing can happen more frequently and everyone can feel more supported throughout the process."
              copyright="Namma® is a registered trademark of Saveraa Inc. DBA Namma.  ©2019-2024 Saveraa Inc. All rights reserved."
            />
          </BrowserRouter>
        </Suspense>
      </Provider >
    </ThemeProvider>
  );
}

export default App;
