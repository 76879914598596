import { TRIGGER_NOTIFICATION } from "./types";

export const triggerGenericNotification = (dispatch, error) => {
  dispatch({
    type: TRIGGER_NOTIFICATION,
    payload: {
      message:
        "Oops, something went wrong.  Please make sure everything is filled out correctly and try again.",
      error: true,
    },
  });
  throw error;
};
